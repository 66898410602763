<template>
  <div class="component-wrap">
    <div class="component-tit">
      {{ $t("mine.my_certificate") }}
      <!-- 我的证书 -->
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="reviewStatus"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="key"
              :placeholder="$t('LB_Coach_EnterName')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="onSearch"
            />
            <!-- 请输入证书名称 -->
          </div>
        </template>
      </ListFilter>
      <!-- 来源 -->
      <ListFilter
        only
        :name="$t('LB_Doc_Source')"
        :allObj="{ name: $t('CM_All'), id: -1 }"
        :dataSource="resourceTypes"
        @onChange="resourceTypeChange"
      />
    </div>
    <div class="list-type-wrap">
      <ListType :checked="listType" @change="(type) => (listType = type)" />
    </div>
    <a-spin :spinning="listLoading">
      <div class="content-wrap">
        <template v-if="dataList.length">
          <div
            class="content"
            :class="{ card: listType == 'card', list: listType == 'list' }"
          >
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <CertificateCard
                :listType="listType"
                :dataSource="item"
              />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              hideOnSinglePage
              :defaultPageSize="15"
              v-model:current="currentPage"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a class="page-a" v-if="type === 'prev'">{{ $t("cm_pre") }}</a>
                <a class="page-a" v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty
          v-if="!dataList.length && !listLoading"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("Pub_Lab_NoData") }}</span>
          </template>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { myCertificate } from "@/api/user";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import ListType from "@/components/new/ListType.vue";
import CertificateCard from "@/components/new/mine/CertificateCard.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    ListFilter,
    ListType,
    CertificateCard,
    renderVNode,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const state = reactive({
      reviewStatus: [
        { id: 1, name: $t("Pub_Normal") }, // 正常
        { id: 2, name: $t("CM_Expired") }, // 已过期
      ],
      resourceTypes: [
        { id: 0, name: $t("issued_online") }, // 线上颁发
      ],
      dataList: [],
      currentPage: 1,
      pageTotal: 1,
      key: "",
      status: 0,
      resourceType: -1,
      listType: "list",
      listLoading: true,
    });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        state.listType = company.useUserCenterShow == 2 ? "card" : "list";
        if (company.menu) {
          if (company.menu.indexOf("project") !== -1) {
            state.resourceTypes.push({
              id: 1,
              name: $t("MN_TrainingProject_MHome"),
            });
            // 培训项目
          }
          if (company.menu.indexOf("newstaff") !== -1) {
            state.resourceTypes.push({ id: 9, name: $t("cm_newstaff") });
            // 新人培训
          }
          if (company.menu.indexOf("subject") !== -1) {
            state.resourceTypes.push({ id: 5, name: $t("cm_subject") });
            // 课程专题
          }
          if (company.menu.indexOf("map") !== -1) {
            state.resourceTypes.push({ id: 6, name: $t("Pub_LP") });
            // 学习地图
          }
          // if (company.menu.indexOf("plan") !== -1) {
          //   state.resourceTypes.push({ id: 14, name: $t("cm_project") });
          //   // 培训实施
          // }
          if (company.menu.indexOf("package") !== -1) {
            state.resourceTypes.push({ id: 8, name: $t("cm_package") });
            // 培训包
          }
          if (company.menu.indexOf("exam") !== -1) {
            state.resourceTypes.push({ id: 10, name: $t("Sys_Exam") });
            // 考试
          }
          if (company.menu.indexOf("qualificationcert") !== -1) {
            state.resourceTypes.push({ id: 11, name: $t("QualificationCertificate") });
            // 资质证书
          }
        }
      },
      { immediate: true }
    );

    // 重新加载list
    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getList(true);
    };

    const getList = (scroll = false) => {
      state.listLoading = true;
      myCertificate({
        page: state.currentPage,
        pageSize: 15,
        key: state.key,
        status: state.status,
        resourceType: state.resourceType,
      }).then((res) => {
        state.listLoading = false;
        state.pageTotal = res.data.totals;
        state.dataList = res.data.list || [];
        if (scroll) {
          setTimeout(() => {
            document.getElementById("pageTop").scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 0);
        }
      });
    };

    const resourceTypeChange = (arr) => {
      state.resourceType = arr[0];
      reloadList();
    };

    const switchStatus = (arr) => {
      state.status = arr[0];
      reloadList();
    };

    const onSearch = () => {
      state.currentPage = 1;
      reloadList();
    };

    getList();

    return {
      ...toRefs(state),
      switchStatus,
      resourceTypeChange,
      pageChange,
      onSearch,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
